import React from 'react';
import './about.css';
import { NavBar } from '../../components/NavBar';
import { Footer } from '../../components/Footer';
import profile from '../../assets/image3.png';
import { Helmet } from 'react-helmet';

function About() {
    return (
        <>
            <Helmet>
                <title>About Us | Zeroday Ops Private Limited</title>
                <meta name="description" content="Zeroday Ops Private Limited is a leading cybersecurity company offering a range of services, including Vulnerability Assessment, Penetration Testing, Red Teaming, and Compliance services." />
                <meta name="keywords" content="Cybersecurity, VAPT, Red Teaming, Penetration Testing, PCI DSS, GRC, ISO Compliance, Cybersecurity Services" />
                <meta name="robots" content="index,follow" />
                <meta property="og:title" content="About Zeroday Ops Private Limited" />
                <meta property="og:description" content="Learn more about Zeroday Ops, a leading cybersecurity company providing top-notch security solutions and services." />
                <meta property="og:image" content="URL_to_your_image" />
                <meta property="og:url" content="https://www.zerodayops.com/about" />
                <link rel="canonical" href="https://www.zerodayops.com/about" />
            </Helmet>

            <NavBar />
            <div className='about-container'>
                <div className="about-section">
                    <h1 style={{ fontWeight: "bold" }}>About Us</h1>
                    <br />
                    <p>Beyond Protection: ZeroDay Ops Safeguards Your Digital Future</p>
                    <br />
                    <p>Zeroday Ops Private Limited is a leading cybersecurity company dedicated to safeguarding organizations against financial and data loss. With a team of highly skilled experts, we deliver cutting-edge cybersecurity solutions tailored to meet the unique needs of our clients. We offer a wide range of cybersecurity services designed to protect and fortify your digital assets. Our services include Vulnerability Assessment and Penetration Testing (VAPT), comprehensive penetration testing for web, network, Android, iOS, thick client, and thin client environments, and firewall penetration testing. Additionally, we specialize in Red Teaming, Security Operations Center (SoC) services, PCI DSS compliance, ISO compliance, and Governance, Risk, and Compliance (GRC) services. Our extensive suite of offerings ensures that we can address all aspects of cybersecurity, providing our clients with the peace of mind that comes from knowing their digital infrastructure is secure.</p>
                    <br />
                    <p>At Zeroday Ops Private Limited, our vision is to create a secure digital world where organizations can thrive without the fear of cyber threats. We aim to be the trusted partner for businesses globally, delivering unparalleled cybersecurity services that empower our clients to focus on their core operations with confidence. Through continuous learning, innovation, and a relentless pursuit of excellence, we strive to set new standards in the cybersecurity industry</p>
                </div>

                <div className="our-team-container">
                    <h2 style={{ textAlign: 'center', fontSize: 40, fontWeight: 'bold' }}>Our Team</h2>
                    <div className="about-row">
                        <div className="about-column">
                            <div className="about-card">
                                {/*<img src={profile} alt="Sahil" style={{ width: '100%', height: '40vh', borderTopLeftRadius: "10%", borderTopRightRadius: "10%", objectFit: 'cover' }} />*/}
                                <div className="about-container">
                                    <br />
                                    <h2>Sahil Kushwah</h2>
                                    <p className="about-title">Director</p>
                                    <p>As a Director at a cybersecurity company, he brings over three years of hands-on experience in the field, specializing in web application and network penetration testing. He holds the eWPTXv2 (Web Application Penetration Tester eXtreme) and eCPPT (Certified Professional Penetration Tester) certifications, which validate his expertise in identifying, analyzing, and mitigating complex security vulnerabilities. His skill set includes Red Teaming, Active Directory, Penetration Testing, Web Application Security, API Security, Mobile Application Security, Network Security, Secure Code Review, Phishing, and Firewall PT.</p>
                                    {/* <p>admin@zerodayops.com</p>
                                <p><button className="about-button">Contact</button></p> */}
                                </div>
                            </div>
                        </div>

                        <div className="about-column">
                            <div className="about-card">
                                {/*<img src={profile} alt="Gautam" style={{ width: '100%', height: '40vh', borderTopLeftRadius: "10%", borderTopRightRadius: "10%", objectFit: 'cover' }} />*/}
                                <div className="about-container">
                                    <br />
                                    <h2>Gautam Nangia</h2>
                                    <p className="about-title">Director</p>
                                    <p>As a Director at a leading cybersecurity firm, he brings over three years of comprehensive experience in the field, with a focus on web application and network penetration testing. He is certified as an eWPTXv2 (Web Application Penetration Tester eXtreme), showcasing his advanced expertise in identifying and addressing complex security vulnerabilities. His extensive skill set includes Red Teaming, Active Directory, Penetration Testing, Web Application Security, API Security, Mobile Application Security, Network Security, Secure Code Review, Phishing, and Firewall PT.<br /><br /></p>
                                    {/* <p>admin@zerodayops.com</p>
                                <br/>
                                <p><button className="about-button">Contact</button></p> */}
                                </div>
                            </div>
                        </div>

                        {/* <div className="about-column">
                            <div className="about-card">
                                <div className="about-container">
                                    <br />
                                    <h2>Satyajit C</h2>
                                    <p className="about-title">CTO</p>
                                    <p>A seasoned software professional with over twenty years of experience across diverse domains, having deep expertise in security, networking, telecommunications, e-commerce, SAAS etc. Known for his versatile skill set and problem-solving abilities, he effectively addresses complex challenges and implements innovative solutions. His strong collaboration skills allow him to work seamlessly with cross-functional teams to deliver high-quality products. Passionate about leveraging technology to drive business success, he continuously explores new trends and methodologies to enhance user experiences and operational efficiency</p>
                                </div>
                            </div>
                        </div> */}
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
}

export default About;
